import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Web3Provider } from "hooks/useWeb3";
import SideNavbar from "./components/SideNavbar";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Account from "./layouts/account";
import Dapps from "./layouts/dapps";
import useUserQuery from "hooks/useUserQuery";
import { useActionBar } from "context/ActionBar";
import NetworkTypeToggle from "components/NetworkTypeToggle";
import Alert from "components/Alert";

interface IHomeProps {}

const Home: React.FunctionComponent<IHomeProps> = (props) => {
  const user = useUserQuery();
  const { currentNode } = useActionBar();
  const location = useLocation();
  if (!user.data) {
    return <Navigate to="/auth" replace state={{ from: location }} />;
  }
  if (user.data && user.data.isOnboarded === false) {
    return <Navigate to="/onboarding" replace state={{ from: location }} />;
  }

  return (
    <Web3Provider>
      <div className="flex flex-grow justify-center max-h-full overflow-hidden min-h-0">
        <SideNavbar />
        <div className="flex flex-col flex-grow bg-background-shade max-h-full overflow-hidden min-h-0">
          <div className="h-[20px] bg-background-shade"></div>
          {user.data?.status === 0 && (
            <Alert color="error" className="mb-4" message="">
              <span className="font-thin text-sm flex-auto">
                Hey! You need to verify your email address to create dapps.
                <Link to="/verify" className="text-biconomy-orange">
                  {" "}
                  Click here{" "}
                </Link>{" "}
                to verify your email address.
              </span>
            </Alert>
          )}
          {user.data?.status === 1 && (
            <Alert color="warning" className="mb-4" message="">
              <span className="font-thin text-sm flex-auto">
                <b>Gasless will be Decommissioned by 31st Dec, 2024.</b> Please switch to alternate service provider before the end date.
              </span>
            </Alert>
          )}
          <Scrollbars
            className="flex-grow flex flex-col rounded-3xl bg-white max-h-full overflow-scroll"
            autoHide
            // Hide delay in ms
            autoHideTimeout={1000}
            // Duration for hide animation in ms.
            autoHideDuration={200}
          >
            <div className="flex flex-col flex-grow p-12">
              <Routes>
                <Route path="/dapps/*" element={<Dapps />} />
                <Route path="/account/*" element={<Account />} />
                <Route path="*" element={<Navigate to="/dapps" replace />} />
              </Routes>
            </div>
          </Scrollbars>
          <div className="h-[20px] bg-background-shade"></div>
        </div>
        <div className="w-[300px] px-12 py-16 h-full bg-background-shade flex flex-col">
          <div className="flex-1">{currentNode}</div>
          <NetworkTypeToggle />
        </div>
      </div>
    </Web3Provider>
  );
};

export default Home;
